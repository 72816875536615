<template>
  <div v-if="isFetchingData" />
  <MobileAppLinks
    v-else-if="displayAppLinks"
    @close="closeMobileApps"
  />
  <div
    v-else
    class="join-call-wrapper"
  >
    <div class="leftColumn">
      <div class="inner">
        <div class="join-call">
          <h4>
            <span>Meeting ID:</span> {{ meetingRoom.webrtc.alias }}
          </h4>
          <h2>
            Welcome to {{ meetingRoom.host.name }} {{ meetingRoom.host.last_name }}'s
            <span v-if="(meetingRoom.brand.call_type === 'meetings') || !meetingRoom.brand.call_type">meeting</span>
            <span v-if="meetingRoom.brand.call_type === 'conferences'">conference</span>
            <span v-if="meetingRoom.brand.call_type === 'other'">{{ meetingRoom.brand.custom_call_type }}</span>
          </h2>

          <BeforeMeeting
            v-if="!isFetchingData && !showMoreWays"
            ref="BeforeMeeting"
            :key="BeforeMeetingKey"
            :webrtc-alias="webrtcAlias"
            :preloaded-meeting-room="meetingRoom"
            :preloaded-display-name="displayName"
            :preloaded-pin="displayPin"
            :preloaded-call-type="callType"
            :error="error"
          />

          <MoreWaysToJoin
            v-if="!isFetchingData && showMoreWays"
            ref="MoreWaysToJoin"
            :show-more-ways="showMoreWays"
            :meeting-room="meetingRoom"
            @close-instructions="closeInstructions"
          />
        </div>

        <OtherWaysToJoin
          v-if="hasMoreWaysToJoin"
          :is-host="false"
          :alias="webrtcAlias"
          :clear="clearButtonFeedback"
          @show="openInstructions"
        >
          <template #title>
            <h4><span>More ways to join</span></h4>
          </template>
        </OtherWaysToJoin>
      </div>
    </div>
    <HeroImage
      :alias="meetingRoom.webrtc.alias"
      :highlight="showMoreWays === 'xr'"
      :type="'xr_guest'"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import JoiningFlowsMixin from '@/mixins/JoiningFlowsMixin';
import DirectLinkMixin from '@/mixins/DirectLinkMixin';
import ResponsiveMixin from '@/mixins/ResponsiveMixin';
import BeforeMeeting from '../../JoinMeeting/JoinMeeting/BeforeMeeting';

import MoreWaysToJoin from '../../JoinMeeting/OtherWaysToJoin/MoreWaysToJoin';
import OtherWaysToJoin from '@/components_v2/intelligent/__COMP_OtherWaysTojoin.vue';
import MobileAppLinks from '@/components/call/MobileAppLinks.vue';
import HeroImage from '@/components_v2/intelligent/branding/__COMP_HeroImage.vue';

import {callStore} from '@/store/__STORE_call';
import {mainStore} from '@/store/__STORE_main';

export default {
  name: 'WelcomeGuest',
  components: {
    BeforeMeeting,
    MoreWaysToJoin,
    MobileAppLinks,
    OtherWaysToJoin,
    HeroImage,
  },
  mixins: [
    JoiningFlowsMixin,
    DirectLinkMixin,
    ResponsiveMixin,
  ],
  props: {
    error: {
      type: String,
      default: null,
    },
    previousRoute: {
      type: Object,
      default: () => {
        return {};
      },
    },
    webrtcAlias: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      displayName: '',
      displayPin: '',
      brand: {
        primary_color_hex: null,
        secondary_color_hex: null,
      },
      backImageStyle: '',
      showHostPin: false,
      highlightQR: false,
      showMoreWays: null,
      BeforeMeetingKey: 0,
      showMobileApps: true,
      clearButtonFeedback: false,
    };
  },
  computed: {
    callType: () => callStore.callType,
    hasMoreWaysToJoin() {
      return this.meetingRoom && (this.meetingRoom.skype || this.meetingRoom.has_phone_license || this.meetingRoom.has_video_device_license || this.meetingRoom.has_xr_license);
    },
    displayAppLinks() {
      if (this.isMobileDevice()) {
        return this.showMobileApps;
      } else {
        return false;
      }
    },
  },
  watch: {
    callType() {
      this.onJoiningFlowMounted();
      this.reRenderBeforeMeeting();
    },
  },
  mounted() {
    this.onJoiningFlowMounted();
    this.displayName = localStorage.getItem('guestDisplayNameTwo') || null;
    this.displayPin = localStorage.getItem('guest_pin') || null;
  },
  destroyed() {
    this.disableBranding();
  },
  methods: {
    closeMobileApps() {
      this.showMobileApps = false;
    },
    onMeetingRoomFetched() {
      this.applyBranding(this.meetingRoom.brand);

      mainStore.setSuccess();
      this.isFetchingData = false;
    },
    openInstructions(tab) {
      this.showMoreWays = (this.showMoreWays == tab ? null : tab);
    },
    closeInstructions() {
      this.clearButtonFeedback = !this.clearButtonFeedback;
      this.showMoreWays = null;
    },
    joinMeeting(e) {
      e.preventDefault();

      const welcomePagePreviousRoute = Vue.util.extend({}, this.previousRoute);
      welcomePagePreviousRoute.header = 'Welcome';

      this.$refs.BeforeMeeting.joinMeeting();
    },
    reRenderBeforeMeeting() {
      this.BeforeMeetingKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  .join-call-wrapper {
    display: flex;
    flex-wrap: nowrap;
    min-height: 100%;

    .leftColumn {
      display: flex;
      flex: 1 0 50%;
      align-items: center;
      justify-content: center;
      padding: 10vh;

      .inner {
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: space-between;
        width: 100%;

        h2 {
          border-bottom: 1px solid #ddd;
          font-size: 24px;
          font-weight: bold;
          margin: 10px 0 25px;
          padding: 0 0 25px;
        }

        h4 {
          font-size: 16px;
          span {
            font-weight: bold;
          }
        }
      }
    }

    .leftColumn {
      min-width: 700px;
      z-index: 2;
    }
  }

  @include media-breakpoint-down(md) {
    .join-call-wrapper {
      flex-direction: column;
      height: 100%;

      .leftColumn {
        flex: 1 0 50%;
        padding: 5vh;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .join-call-wrapper {
      display: block;
      margin-top: 78px;

      .leftColumn {
        display: block;
        min-width: 0;
      }
    }
  }
</style>
